// used for firm and client logos
export default {
    methods: {
        logoLoadFailed(type = 'client') {
            const project = this.project || this._project;
            if (!project) {
                return;
            }
            // first we try to load custom logos from project portal
            if (project.portal[type + 'LogoUrl']) {
                project.portal[type + 'LogoUrl'] = '';
            } else {
                project[type].logo_url = '';
            }
        },
    },
};