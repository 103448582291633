<template>
    <div
        class="c-cp-logo-rectangle"
        :style="rectangleStyle"
    >
        <img
            class="c-cp-logo-rectangle__logo"
            :src="logo"
            :style="logoStyle"
        >
    </div>
</template>

<script>
import images from '@js/client-portal/vue-mixins/images.js';

export default {
    name: 'CpLogoRectangle',
    mixins: [images],
    emits: ['loadFailed'],
    props: {
        rectangleStyle: String,
        logoStyle: String,
        logo: String,
    },
    created() {
        if (this.logo) {
            this.checkImage(this.logo, {}, () => {
                this.$emit('loadFailed');
            });
        }
    },
};
</script>

<style lang="scss" scoped>

.c-cp-logo-rectangle {
    align-self: center;
    max-height: 100%;
    max-width: 100%;

    &__logo {
        height: auto;
        max-height: 40px;
        max-width: 180px;
        object-fit: contain;
        width: auto;
    }
}

@media (max-width: 768px) {
    .c-cp-logo-rectangle {
        &__logo {
            max-width: 90px;
        }
    }
}
</style>
