<template>
    <div>
        <cp-menu-items
            :projectsList="projectsList"
            :itemsList="itemsList"
            :currentProjectId="currentProjectId"
            @action="action"
            @switchProject="switchProject"
        >
        </cp-menu-items>
        <form
            ref="logout"
            method="POST"
            action="/logout"
        >
            <input
                type="hidden"
                name="_token"
                :value="token"
            >
        </form>
    </div>
</template>

<script>
import CpMenuItems from '@js/client-portal/vue-components/layout/menu/cp-menu-items.vue';
import interactsWithProjectSwitch from '@js/client-portal/vue-mixins/interactsWithProjectSwitch.js';

export default {
    name: 'CpMenuClient',
    components: {
        CpMenuItems,
    },
    mixins: [
        interactsWithProjectSwitch,
    ],
    data() {
        return {
            open: false,
        };
    },
    computed: {
        token() {
            return window.token.content;
        },
        itemsList() {
            return [
                {
                    title: trans('auth.buttons.reset'),
                    iconClass: 'far fa-key',
                    action: 'resetPassword',
                },
                {
                    title: trans('auth.buttons.logout'),
                    iconClass: 'far fa-sign-out-alt',
                    action: 'logout',
                },
            ];
        },
    },
    methods: {
        resetPassword() {
            if (this.$router.name !== 'reset') {
                return this.$router.push('/reset');
            }
        },
        logout() {
            return this.$refs.logout.submit();
        },
    },
};

</script>
