import { createRouter, createWebHashHistory } from 'vue-router';

import Analytics from '@js/core/cookies/analytics.js';
import { isLoggedIn, requireAuth } from './helpers/interactsWithRoutes.js';

const Register = () => import('./vue-components/register/cp-register.vue');
const Portal = () => import('./vue-components/main/cp-portal.vue');

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        {
            path: '/login/:firm?/:project?',
            component: Register,
            name: 'login',
            beforeEnter(to, from, next) {
                if (window.browserNeedsVerification) {
                    return next({
                        name: 'verify',
                    });
                }
                if (isLoggedIn()) {
                    return next({
                        name: 'project',
                    });
                }
                return next();
            },
        },
        {
            path: '/reset',
            component: Register,
            name: 'reset',
            //if user role is search then redirect to home
            beforeEnter(to, from, next) {
                if (window.nal.user?.role === 'search') {
                    return next({
                        name: 'project',
                    });
                }
                return next();
            },
        },
        {
            path: '/recover/:firm?',
            component: Register,
            name: 'recover',
            meta: {
                guest: true,
            },
        },
        {
            path: '/resend/:firm?',
            component: Register,
            name: 'resend',
            meta: {
                guest: true,
            },
        },
        {
            path: '/invalid',
            component: Register,
            name: 'invalid',
            meta: {
                guest: true,
            },
        },
        {
            path: '/register/:firm/:project?',
            component: Register,
            name: 'register',
            meta: {
                guest: true,
            },
        },
        {
            path: '/verify',
            component: Register,
            name: 'verify',
            beforeEnter(to, from, next) {
                if (!window.browserNeedsVerification) {
                    return next({
                        name: 'login',
                    });
                }
                return next();
            },
        },
        {
            path: '/terms/:tab?',
            component: () => import('./vue-components/layout/cp-terms.vue'),
            name: 'terms',
            meta: { top: true },
        },
        {
            path: `/project/:id/:tab?`,
            component: Portal,
            name: 'project.model',
            // props: () => {
            //     return {
            //         useRoute: true,
            //     };
            // },
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: `/project`,
            component: Portal,
            name: 'project',
            // props: () => {
            //     return {
            //         useRoute: true,
            //     };
            // },
            meta: {
                requiresAuth: true,
            },
        },
        {
            path: '/',
            redirect: '/login',
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: () => {
                return '/login';
            },
        },
    ],
    linkActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.meta.noScroll) {
            return false;
        }
        return { left: 0, top: 0 };
    },
});

router.beforeEach((to, from, next) => {
    if (requireAuth(to) && !isLoggedIn()) {
        return next({
            name: 'login',
            query: { redirect: to.fullPath },
        });
    }
    if (to.matched.some(record => record.meta.guest)) {
        if (isLoggedIn()) {
            return next({
                name: 'project',
            });
        }
    }
    next();
});

Analytics.setPage({ path: router.currentRoute.path });

router.afterEach((to) => {
    Analytics.setPage({
        path: to.path,
        extra: {
            hitType: 'cp-page-view',
            title: window.nal.firm?.name + ' -' + to.path,
        },
    });
    if (to.meta.top) {
        document.body.scrollTop = 0;
    }
});

export default router;
