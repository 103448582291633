<template>
    <div class="c-cp-menu-slide">
        <div
            class="c-cp-menu-slide__title"
            @click="open = !open"
        >
            <slot name="title"></slot>
            <div
                class="c-cp-menu-slide__icon"
                :class="{ 'c-cp-menu-slide__icon--open': open }"
            >
                <i
                    class="fas fa-caret-down u-transition__transform"
                    :class="{ 'u-rotate': open }"
                >
                </i>
            </div>
        </div>
        <slide :condition="open">
            <div class="c-cp-menu-slide__inner">
                <slot name="inner"></slot>
            </div>
        </slide>
    </div>
</template>

<script>

export default {
    name: 'CpMenuSlide',

    props: {
        state: Boolean,
    },
    data() {
        return {
            open: this.state,
        };
    },
    computed: {

    },
};

</script>

<style lang="scss" scoped>
.c-cp-menu-slide {
    border-bottom: 1px solid #ccc;
    margin-bottom: 8px;
    padding-bottom: 8px;

    &__title {
        align-items: center;
        border-radius: 6px;
        color: #aaa;
        display: flex;
        gap: 12px;
        justify-content: space-between;

        &:hover {
            background-color: #f2f2f2;
        }

    }

    &__inner {

    }

    &__icon {
        color: #aaa;
        padding: 4px 8px;
        transition: all 150ms ease-in;

        &--open {
    
        }
    }
}

.u-rotate {
    transform: rotate(180deg);
}

.u-transition__transform {
    transition: transform 0.3s ease-out;
}
</style>
