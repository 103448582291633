<template>
    <div class="c-cp-invite">
        <div
            class="icon-button u-primary-color__text"
            :title="trans('client-portal.invite-btn.invite')"
            @click="openInviteModal"
        >
            <i
                class="fa-solid fa-user-plus icon-button__icon"
                aria-hidden="true"
            ></i>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue';

export default {
    name: 'CpInvite',
    emits: ['close'],
    props: {
        project: {
            type: Object,
            required: true,
        },
    },
    methods: {
        openInviteModal() {
            this.$emit('close');
            this.createModal({
                data: {
                    component: defineAsyncComponent(() =>
                        import('@js/client-portal/vue-components/settings/invite/cp-invite-contacts.vue')),
                    style: { width: '700px' },
                    data: {
                        project: this.project,
                    },
                    on: {
                        invited: () => {
                            app.closeCurrentModal();
                        },
                    },
                },
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.c-cp-invite {
    position: relative;
}
</style>
