<template>
    <div
        v-blur="hidePopup"
        class="o-cp-menu"
    >
        <div
            class="o-cp-menu__button u-hover-text"
            @click="popupVisible = !popupVisible"
        >
            <i class="far fa-ellipsis-v o-cp-menu__icon">
            </i>
        </div>
        <transition name="flyout">
            <basic-popup
                v-show="popupVisible"
                :popupStyle="popupStyle"
                :hideTriangle="true"
            >
                <component
                    v-if="isLoggedIn"
                    :is="'cp-menu-' + nal.user.true_role"
                    @close="popupVisible = false"
                >
                </component>
            </basic-popup>
        </transition>
    </div>
</template>

<script>
import CpMenuClient from '@js/client-portal/vue-components/layout/menu/cp-menu-client.vue';
import CpMenuSearch from '@js/client-portal/vue-components/layout/menu/cp-menu-search.vue';

export default {
    name: 'CpMenu',
    components: {
        'cp-menu-client': CpMenuClient,
        'cp-menu-search': CpMenuSearch,
    },
    data() {
        return {
            popupVisible: false,
        };
    },
    computed: {
        isLoggedIn() {
            return !(_.isEmpty(this.nal.user));
        },
        popupStyle() {
            return { right: '0', left: 'auto', marginTop: '8px', minWidth: '180px', borderRadius: '6px' };
        },
    },
    methods: {
        hidePopup() {
            this.popupVisible = false;
        },
    },
};

</script>

<style lang="scss" scoped>

.o-cp-menu {
    color: #aaa;
    font-size: 24px;
    position: relative;

    &__button {
        align-items: center;
        border-radius: 6px;
        display: flex;
        height: 36px;
        padding: 0 12px;
        transition: all 150ms ease-in;
    }
}

</style>
