import { defineAsyncComponent } from 'vue';
import $ from 'jquery';
import VueApexCharts from 'vue3-apexcharts';
import Bloodhound from 'corejs-typeahead/dist/bloodhound.js';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';

import '@sass/client-portal/app.scss';
import './lang.js';
import '../_app.js';
import App from '@js/client-portal/vue-components/cp-app.vue';
import EzekiaLocalStorage from '../core/EzekiaLocalStorage.js';
import { prefetchCurrencies } from '@js/core/currencies.js';
import interactsWithProject from '@js/client-portal/vue-mixins/interactsWithProject.js';
import router from './router.js';

const components = {

    // Settings --------------------------------------------------------------------------------------------
    'CpSettingsRestrict': import('@js/client-portal/vue-components/settings/cp-settings-restrict.vue'),
    'CpSettingsDisable': import('@js/client-portal/vue-components/settings/cp-settings-disable.vue'),
    'CpSettingsCurrency': import('@js/client-portal/vue-components/settings/cp-settings-currency.vue'),
    // -------- Common
    'SaveCancel': import('../nal/vue-components/common/buttons/save-cancel.vue'),
    // Notifications
    'Notifications': import('@js/vue-components/general/notification/notifications.vue'),

    // Layout - used in blade file
    'NalFooter': import('@js/vue-components/layout/nal-footer.vue'),
    'LeaveImpersonate': import('../nal/vue-components/layout/leave-impersonate.vue'),

    // Basic sorting
    'BasicSorting': import('@js/nal/vue-components/common/display/basic-sorting.vue'),
    // Candidates
    'CandidateInfoSection': import('@js/client-portal/vue-components/page/candidates/candidate-info-section.vue'),

    // Candidates Info
    'FormPreview': import('@js/nal/vue-components/gdpr/forms/form-preview.vue'), //used in blade file

    // Components need to allow inline editing
    'CareerDropdown': import('../nal/vue-components/common/dropdowns/career-dropdown.vue'),
    'IndustryDropdown': import('../nal/vue-components/common/dropdowns/industry-dropdown.vue'),
    'LocationDropdown': import('../nal/vue-components/common/dropdowns/location-dropdown.vue'),
    'CompanytypeDropdown': import('../nal/vue-components/common/dropdowns/companytype-dropdown.vue'),
    'SelectionsDropdown': import('../nal/vue-components/common/dropdowns/selections-dropdown.vue'),
    'PositionDropdown': import('@js/nal/vue-components/common/dropdowns/position-dropdown.vue'),
    //Common Display
    'ExplanationsDisplay': import('@js/nal/vue-components/common/display/explanations-display.vue'),
    // Feedback
    'Feedback': import('@js/client-portal/vue-components/page/feedback/feedback.vue'),
    'FeedbackForm': import('@js/client-portal/vue-components/page/feedback/feedback-form.vue'),
    // Tasks and Meetings
    'MeetingsContent': import('@js/client-portal/vue-components/page/tasks-meetings/meetings-content.vue'),
    'TaskMeetingItem': import('@js/client-portal/vue-components/page/tasks-meetings/task-meeting-item.vue'),
    'TasksContent': import('@js/client-portal/vue-components/page/tasks-meetings/tasks-content.vue'),
    // Timeline
    'TimelineContent': import('@js/client-portal/vue-components/page/timeline/timeline-content.vue'),
    'TimelineItem': import('@js/client-portal/vue-components/page/timeline/timeline-item.vue'),
    // Documents
    'DocumentViewButton': import('@js/client-portal/vue-components/page/documents/document-view-button.vue'),
    // End of components in folder "Page"
    'CategoryField': import('@js/nal/vue-components/managers/custom-fields/category-field.vue'),

    'DragOverlay': import('@js/vue-components/features/drag-overlay.vue'),
    // model look-up components
    'FindProject': import('@js/nal/vue-components/search/find-project.vue'),
    'FindCompany': import('@js/nal/vue-components/search/find-company.vue'),
    //Auth
    'PasswordErrorsAlert': import('@js/vue-components/auth/password-errors-alert.vue'),
    'KeyContent': import('@js/nal/vue-components/common/display/key-content.vue'),
    'AttachButton': import('@js/nal/vue-components/common/buttons/attach-button.vue'),
};

window.packageBuild = 'cp';

window.momentTz = require('moment-timezone');
window.token = document.head.querySelector('meta[name="csrf-token"]');
// Check for client preview
if (nal.user && nal.user.role) {
    nal.user.true_role = nal.user.role;
}
//End check for client preview

window.$ = window.jQuery = $;

$.ajaxPrefilter(function (options, originalOptions, jqXHR) {
    jqXHR.setRequestHeader('X-CSRF-Token', token.content);
});
// End Jquery

// Location
let setCountry = (country, resetCache = true) => {
    window.setVueObject(window.nal, 'country', country);
    window.setCountry(country);
    if (resetCache) {
        EzekiaLocalStorage.set('country', country);
    }
};
(() => {
    if (EzekiaLocalStorage.get('country')) {
        setCountry(EzekiaLocalStorage.get('country'), false);
        return;
    }
    let ipInfoUrl = 'https://ipinfo.io/json';
    const token = import.meta.env.VITE_IPINFO_ACCESS_TOKEN;
    if (!!token && token !== 'undefined') {
        ipInfoUrl += `?token=${token}`;
    }
    $.ajax(ipInfoUrl)
        .done(data => setCountry(data?.country ?? 'GB'))
        .fail(() => setCountry('GB'));
})();

prefetchCurrencies();

window.mainSite = false;
window.typeahead = require('corejs-typeahead');

// Load categories

window.nal.categories.locations = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value', 'matches'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    identify: (obj) => (obj.value),
    limit: 10,
    remote: {
        url: '/api/categories/locations?query=%QUERY',
    },
});
window.nal.categories.companies = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    limit: 10,
    remote: {
        url: '/api/categories/companies?query=%QUERY',
    },
});
if (nal.user && nal.user.role) {
    window.nal.categories.locations.initialize();
    window.nal.categories.companies.initialize();

    $.ajax('/api/categories').done(function (data) {
        window.nal.categories.careers = data.careers;
        window.nal.categories.industries = data.industries;
        window.nal.categories.classifications = data.classifications;
        window.nal.categories.types = data.companyTypes;
        window.nal.categories.languages = data.languages;
        window.nal.categories.sizes = data.teamSizes;
        window.nal.categories.locations.clear();
        window.nal.categories.locations.add(data.countries);
    });
}

// End load categories

window._mixins = {
    interactsWithModel: interactsWithProject,
};

//Initializing vue

import('../vue3factory.js').then(({ createMyApp }) => {
    const clientPortalApp = createMyApp(App);

    // Register Mixins
    clientPortalApp.mixin({
        computed: {
            isSearch() {
                return this.nal.user.role === 'search';
            },
            isTrueSearch() {
                return this.nal.user.true_role === 'search';
            },
            isClient() {
                return this.nal.user.role === 'client';
            },
        },
    });

    // Register Use packages
    clientPortalApp.use(VCalendar);
    clientPortalApp.use(VueApexCharts);
    clientPortalApp.use(router);

    // Not in testing
    if (process.env.NODE_ENV !== 'testing') {
        components.Tinymce = import('@js/vue-components/general/inputs/tinymce.js');
    }
    // End not in testing

    // Register each existing component
    for (const [componentName, componentImport] of Object.entries(components)) {
        clientPortalApp.component(componentName, defineAsyncComponent(() => componentImport));
    }

    window.app = clientPortalApp.mount('#app');
});