<template>
    <button
        class="o-cp-preview button1"
        :class="{ 'u-primary-color__bg--messages u-primary-color__text': clientPreview,
                  'u-no-pointer': isSaving,
                  'u-hover-text': !isSaving,
        }"
        :title="trans('client-portal.preview-btn.' + (clientPreview ? 'return' : 'preview'))"
        :disabled="isSaving"
        @click="togglePreview"
    >
        {{ trans('client-portal.preview-btn.text') }}
        <i
            class="o-cp-preview__icon"
            :class="faIcon"
        ></i>
    </button>
</template>

<script>

export default {
    name: 'CpPreview',
    props: {
        client: {
            type: Object,
            required: true,
        },
        isSaving: Boolean,
    },
    data() {
        return {
            clientPreview: nal.user.role === 'client',
            notification: false,
        };
    },
    computed: {
        faIcon() {
            if (this.isSaving) {
                return 'far fa-circle-dot fa-beat-fade';
            }

            return this.clientPreview ? 'fal fa-times' : 'fa fa-eye';
        },
    },
    methods: {
        togglePreview() {
            this.clientPreview = !this.clientPreview;
            this.nal.user.role = this.clientPreview ? 'client' : 'search';
            this.updateData();
            if (this.clientPreview) {
                const message = trans('client-portal.preview-btn.view', { client: this.client.name });
                this.notification = notify(message, 'primary');
            } else {
                window.NotificationStore.removeNotification(this.notification);
            }
        },
        deleteHiddenTabs(project) {
            _(project.portal.tabs).each((tab, key) => {
                if (!tab.visible) {
                    window.deleteKeyFromVueObject(project.portal.tabs, key);
                }
            });
        },
        deleteAllTabs(project) {
            _(project.portal.tabs)
                .keys()
                .each(key => window.deleteKeyFromVueObject(project.portal.tabs, key));
        },
        revertOldTabs(project, oldTabs) {
            _(oldTabs).each((tab, key) => {
                // this is triggering unnecessary overrides update
                window.setVueObject(project.portal.tabs, key, tab);
            });
        },
        updateData() {
            _(this.nal.projects).each(project => {
                if (this.clientPreview) {
                    project.oldTabs = _(project.portal.tabs).clone();
                    this.deleteHiddenTabs(project);
                    this.reloadTabs(project);
                } else {
                    this.deleteAllTabs(project);
                    this.revertOldTabs(project, project.oldTabs);
                    this.reloadTabs(project);
                }
            });
        },
        reloadTabs(project) {
            // <cp-tab> logic:
            // if project[tab-name] is not present
            // then the data is loaded in project[tab-name]
            // and 0 is set in tab_node[tab-name]
            // if tab_node[tab-name] changes to 1, then data is reloaded

            // deletes all loaded tabs data
            _(project.tab_nodes).keys().each(key => window.deleteKeyFromVueObject(project, key));

            // trigger data reload
            // portal visible tabs (tabs overrides) keys are used to match with previously loaded tabs
            _(project.portal.tabs).keys().each(key => {
                if (_(project).has(`tab_nodes.${key}`)) {
                    project.tab_nodes[key] += 1;
                }
            });
        },
    },
};

</script>

<style lang="scss" scoped>

.o-cp-preview {
    align-items: center;
    color: #797979;
    gap: 6px;
    height: 36px;
    transition: all 150ms ease-in;
    white-space: nowrap;

    &__icon {
        font-size: 16px;
        text-align: center;
        width: 18px;
    }
}

</style>