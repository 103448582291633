<template>
    <div class="o-cp-menu-items">
        <cp-menu-slide>
            <template #title>
                <div class="o-cp-menu-items__header">
                    {{ trans('client-portal.common.projects') }}
                </div>
            </template>
            <template #inner>
                <div
                    v-for="project in projectsList"
                    :key="project.id"
                    class="o-cp-menu-items__item"
                    :title="project.client + ' - ' + project.title"
                    @click="$emit('switchProject', project.id)"
                >
                    <div class="o-cp-menu-items__title">
                        {{ project.title }}
                        <i
                            v-if="project.id === currentProjectId"
                            class="far fa-check"
                        >
                        </i>
                    </div>
                </div>
            </template>
        </cp-menu-slide>
        <div
            v-for="(item, index) in itemsList"
            :key="item.title + index"
            class="o-cp-menu-items__item"
            @click="$emit('action', item.action)"
        >

            <i :class="item.iconClass"></i>
            {{ item.title }}
        </div>
    </div>
</template>
<script>
import CpMenuSlide from '@js/client-portal/vue-components/layout/menu/cp-menu-slide.vue';

export default {
    name: 'CpMenuItems',
    components: {
        CpMenuSlide,
    },
    emits: ['action', 'switchProject'],
    props: {
        projectsList: {
            type: Array,
            default: () => [],
        },
        itemsList: {
            type: Array,
            default: () => [],
        },
        currentProjectId: {
            type: Number,
            default: 0,
        },
    },
};

</script>
<style lang="scss" scoped>

.o-cp-menu-items {
    font-size: 13px;

    &__item {
        align-items: center;
        border-radius: 6px;
        color: var(--dark);
        cursor: pointer;
        display: flex;
        gap: 12px;
        padding: 8px;

        &:hover {
            background-color: #f2f2f2;
        }

        & .far {
            color: var(--gray)
        }
    }

    &__title {
        display: flex;
        gap: 12px;
        justify-content: space-between;
        width: 100%;
    }

    &__header {
        align-items: center;
        border-radius: 6px;
        display: flex;
        gap: 12px;
        padding: 8px;
    }
}
</style>