<template>
    <div class="o-cp-header">
        <div class="o-cp-header__left">
            <div
                class="o-cp-header__logo"
                :class="imageClass"
                @click="openImageEditModal"
            >
                <cp-logo-rectangle
                    v-if="firmLogo"
                    :logo="firmLogo"
                    @loadFailed="logoLoadFailed('firm')"
                >
                </cp-logo-rectangle>
                <div
                    v-else
                    class="u-primary-color__text u-ellipsis"
                >
                    {{ nal.firm.name }}
                </div>
                <span
                    v-if="projectId && isAuthEdit"
                    class="o-cp-header__edit-image u-hover-border u-hover-text"
                    :title="trans('common.edit')"
                >
                    <i class="fal fa-pencil-alt"> </i>
                </span>
            </div>
        </div>
        <div class="o-cp-header__right">
            <template v-if="isLoggedIn">
                <i
                    v-if="isSearch && isDisabled"
                    class="fa-regular fa-shield-exclamation o-cp-header__disabled u-hover"
                    :title="trans('client-portal.settings.disabled-project')"
                    @click="showSettingsModal"
                ></i>
                <cp-invite
                    v-if="isSearch && _project"
                    :project="_project"
                ></cp-invite>
                <cp-preview
                    v-if="isTrueSearch && hasProjects && _project"
                    :isSaving="isSaving"
                    :client="_project.client"
                >
                </cp-preview>
                <cp-menu></cp-menu>
            </template>
            <div
                v-else
                class="o-cp-header__login"
            >
                <router-link
                    :to="{ name: 'login' }"
                    class="u-hover-text"
                >
                    {{ trans('auth.buttons.login') }}
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import CpMenu from '@js/client-portal/vue-components/layout/menu/cp-menu.vue';
import CpPreview from '@js/client-portal/vue-components/layout/buttons/cp-preview.vue';
import CpInvite from '@js/client-portal/vue-components/layout/buttons/cp-invite.vue';
import CpLogoRectangle from '@js/client-portal/vue-components/general/cp-logo-rectangle.vue';

import interactsWithProject from '@js/client-portal/vue-mixins/interactsWithProject.js';
import interactsWithLogoLoad from '@js/client-portal/vue-mixins/interactsWithLogoLoad.js';
import { defineAsyncComponent } from 'vue';

export default {
    name: 'CpHeader',
    components: {
        CpMenu,
        CpPreview,
        CpInvite,
        CpLogoRectangle,
    },
    mixins: [
        interactsWithProject,
        interactsWithLogoLoad,
    ],
    props: {
        isSaving: Boolean,
    },
    data() {
        return {
            processing: false,
        };
    },
    computed: {
        firmLogo() {
            if (!this.isLoggedIn) {
                return this.nal.logo || '';
            }
            if (this.hasProjects && !this.projectId) {
                const firstFirmProject = this.projects.find(project => project.search_firm_id === this.nal.firm.id);
                return firstFirmProject?.portal.firmLogoUrl || this.originalFirmLogo;
            }
            return this.portalFirmLogo || this.originalFirmLogo;
        },
        originalFirmLogo() {
            return this.nal.firm.logo_url || '';
        },
        portalFirmLogo() {
            return this.projectId && this._project.portal.firmLogoUrl || '';
        },
        isDisabled() {
            return this.projectId && this._project.portal.disabled;
        },
        isAuthEdit() {
            return this.isSearch
                && this.projectId
                && this._project
                && window.auth('edit', this._project.label.plural(), this._project);
        },
        isLoggedIn() {
            return !!this.nal.user?.role;
        },
        imageClass() {
            return this.isAuthEdit
                ? 'u-hover u-primary-color__border--hover'
                : 'u-no-pointer';
        },
    },
    methods: {
        updateFirmLogo({ firmLogoUrl, firmLogoColor }) {
            this.updateProjectPortal(this.projectId, firmLogoUrl, 'firmLogoUrl');
            this.updateProjectPortal(this.projectId, firmLogoColor, 'firmLogoColor');
        },
        openImageEditModal() {
            if (this.isAuthEdit) {
                this.createModal({
                    data: {
                        component: defineAsyncComponent(() =>
                            import('@js/client-portal/vue-components/general/images/cp-image-edit-modal.vue')),
                        style: { height: 'fit-content' },
                        data: {
                            defaultImage: this.originalFirmLogo,
                            image: this.portalFirmLogo,
                            formKey: 'firmLogoUrl',
                            formUrl: `portal/${this._project.portal.id}`,
                            title: trans('client-portal.common.edit-image'),
                        },
                        on: {
                            logoUpdate: (data) => this.updateFirmLogo(data),
                        },
                    },
                });
            }
        },
        showSettingsModal() {
            this.createModal({
                data: {
                    component: defineAsyncComponent(() =>
                        import('@js/client-portal/vue-components/settings/cp-settings.vue')),
                    style: { width: '700px' },
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@sass/mixins";

.o-cp-header {
    align-items: center;
    column-gap: 14px;
    display: flex;
    justify-content: space-between;
    max-width: 1100px;
    width: 100%;

    &__right, &__left {
        align-items: center;
        column-gap: 14px;
        display: flex;
        position: relative;
    }

    &__right {
        column-gap: 6px;
    }

    &__styling {
        content: '';
        height: 2px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }

    &__logo {
        align-items: center;
        display: flex;
        height: 40px;
        max-width: 180px;
        overflow: hidden;
    }

    &__disabled {
        color: var(--warning);
        font-size: 26px;
        padding: 6px;
    }

    &__edit-image {
        position: absolute;
        right: -5px;
        top: 25px;

        @include circle(20px, 10px, #333, #fafafa, #ccc);
    }
}

@media (max-width: 1200px) {
    .o-cp-header {
        padding: 0 40px;
    }
}

@media (max-width: 768px) {
    .o-cp-header {
        padding: 0 14px;

        &__firm-name {
            font-size: 13px;
        }

        &__logo {
            max-width: 90px;
        }
    }
}
</style>