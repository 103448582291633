export default {
    emits: ['close'],
    computed: {
        currentTab() {
            return this.$route.params.tab || '';
        },
        currentProjectId() {
            return +this.$route.params.id || 0;
        },
        projectsList() {
            return this.nal.projects.map(project => ({
                // only project and client used
                title: project.portal.title || project.title,
                id: project.brief_id,
                client: project.client.name,
                clientId: project.client_id,
                firmId: project.search_firm_id,
            }));
        },
    },
    methods: {
        action(method) {
            this[method]();
        },
        switchProject(id) {
            this.get(`/change-project/${id}`)
                .then(response => {
                    if (this.currentProjectId !== id) {
                        const firm = response.data;
                        if (firm.id !== this.nal.firm.id) {
                            window.setVueObject(this.nal, 'firm', firm);
                            this.changeColor(firm);
                        }
                        this.$router.push(this.routeUrl(id, this.currentTab));
                    }
                }).always(this.$emit('close'));
        },
        changeColor(firm) {
            let allColorShades = firm.firmSettings;
            let el = document.getElementById('custom-colors');
            let text = el.innerHTML.replace(
                /(#\w*)([^;]*;\s*\/\*@change([^*]*)\*\/)/g,
                (match, p1, p2, p3) => {
                    return allColorShades['brand_color' + (p3 && '_' + p3)] + p2;
                }
            );
            el.innerHTML = text.replace(/\n/g, '');
        },
        routeUrl(id, tab) {
            return `/project/${id || ''}` + ((id && tab) ? `/${tab}` : '');
        },
    },
};